export const DefaultAssets = {
   images: {
      user: '/image/default-user.svg',
      logo: '/image/logo.svg',
      coverage: {
         insurance: '/image/register/health.svg',
         company: '/image/register/company.svg',
         outOfPocket: '/image/register/outOfPocket.svg',
      },
      confirmBenefitCode: '/image/register/confirm-benefit-code.png',
      brain: '/image/register/brain.svg',
      therapyReasons: '/image/register/therapyReasons.png',
      ageGroups: '/image/register/age.png',
      gender: '/image/register/gender.png',
      email: '/image/email.png',
      pending: '/image/register/pending.png',
      teenTherapyParent1: '/image/register/teenTherapyParent-1.svg',
      teenTherapyParent2: '/image/register/teenTherapyParent-2.svg',
      teenTherapyParent3: '/image/register/teenTherapyParent-3.svg',
      teenTherapyParent4: '/image/register/teenTherapyParent-4.svg',
      chat: '/image/chat.svg',
      support: '/image/support.svg',
   },
};
